import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Acrylic Render`}</h1>
    <p>{`Acrylic render is a finishing coat for render, containing acrylic aggregates to display an attractive finish. It is applied to new and existing render to seal the substrate layer and enhance the appearance of the elevation.`}</p>
    <p>{`Acrylic render is available in a wide range of colours and textures ranging from 1mm to 3mm aggregate size.`}</p>
    <p>{`Acrylic renders incorporate anti-cracking technology by using minute reinforcing fibres to produce an incredibly tough and durable finish. The Acrylic render can be applied by a steel float or spray application. When spray applied, a Tyrolean finish can easily be achieved.`}</p>
    <p>{`Suitable substrates: Light weight thin coat render system, cement render in good condition and good quality brick and stone.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      